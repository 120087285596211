<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <router-link :to="`/profile/child/${child.id}/calendar`" class="back-button" title="back">
            <ion-icon slot="icon-only" color="tetranary" :icon="chevronBackOutline"></ion-icon>
          </router-link>
        </ion-buttons>

        <ion-title color="primary">
          <h2 class="mb-0 ion-text-center">Sleep Diary</h2>
        </ion-title>

        <ion-buttons slot="end">
          <div style="visibility: hidden"><ion-back-button default-href="/"></ion-back-button></div>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :scroll-events="true" class="bg-white text-black">
      <form @submit.prevent="saveChanges" class="horizontally-centered">
        <div class="ion-padding">
          <h2>Sleep diary for {{ child.initials }}</h2>
        </div>
        <ion-grid>
          <ion-row v-for="(field, i) in formFields" :key="i">
            <template v-if="showCondition(field)">
              <ion-col v-if="field.type === 'radio'">
                <ion-radio-group v-model="formValues[field.label]">
                  <ion-list-header>
                    <ion-label mode="md" position="stacked">{{ field.label }}</ion-label>
                  </ion-list-header>

                  <ion-row>
                    <ion-col v-for="(option, index) in field.options" :key="index">
                      <ion-item lines="none">
                        <ion-radio :name="field.label" mode="md" :value="option"></ion-radio>
                        <ion-label>{{ option }}</ion-label>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-radio-group>
              </ion-col>
              <ion-col v-else-if="field.type === 'time'">
                <ion-label position="stacked">{{ field.label }}</ion-label>
                <ion-datetime
                  display-format="HH:mm"
                  :name="field.label"
                  minute-values="0,5,10,15,20,25,30,35,40,45,50,55"
                  v-model="formValues[field.label]"
                ></ion-datetime>
              </ion-col>
              <ion-col v-else-if="field.type === 'select'">
                <ion-label position="stacked">{{ field.label }}</ion-label>
                <ion-note class="mb-0" v-if="field.note">{{ field.note }}</ion-note>
                <ion-select
                  :name="field.label"
                  :value="formValues[field.label]"
                  :multiple="field.multiple"
                  @ionChange="forceChange(field.label)($event.target.value)"
                  @ionBlur="forceChange(field.label)($event.target.value)"
                >
                  <ion-select-option v-for="option in field.options" :value="option" :key="option">
                    {{ option }}
                  </ion-select-option>
                </ion-select>
              </ion-col>
              <ion-col v-else-if="field.type === 'textarea'">
                <ion-label position="stacked">{{ field.label }}</ion-label>
                <ion-textarea
                  :name="field.label"
                  class="md"
                  v-model="formValues[field.label]"
                ></ion-textarea>
              </ion-col>
              <ion-col v-else>
                <ion-label position="stacked">{{ field.label }}</ion-label>
                <ion-input
                  :name="field.label"
                  v-model="formValues[field.label]"
                  :type="field.type"
                  :placeholder="field.placeholder"
                ></ion-input>
                <ion-text v-if="field.append">{{ field.append }}</ion-text>
              </ion-col>
            </template>
          </ion-row>

          <ion-row>
            <ion-col>
              <div class="ion-padding-top">
                <ion-button expand="block" size="large" color="tertiary" type="submit">
                  <ion-spinner v-if="hasBeenSubmitted" name="crescent"></ion-spinner>
                  Save entry
                  <ion-ripple-effect></ion-ripple-effect>
                </ion-button>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import axios from '@/axios';
import { computed, reactive, toRefs, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import {
  IonText,
  IonTextarea,
  IonDatetime,
  IonRadioGroup,
  IonListHeader,
  IonRadio,
  IonLabel,
  IonInput,
  IonCol,
  IonRow,
  IonPage,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  toastController
} from '@ionic/vue';

import { chevronBackOutline } from 'ionicons/icons';

export default {
  name: 'ChildCalendarEdit',
  components: {
    IonText,
    IonTextarea,
    IonDatetime,
    IonRadioGroup,
    IonListHeader,
    IonRadio,
    IonLabel,
    IonInput,
    IonCol,
    IonRow,
    IonPage,
    IonButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent
  },
  setup(): any {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const childId = computed(() => Number(route.params.childId) || null);
    const entryId = computed(() => Number(route.params.entryId) || null);
    const child = computed(() => store.getters['user/childById'](childId.value));

    const form = reactive({
      hasLoaded: false,
      hasBeenSubmitted: false,
      hasChanges: false,
      formFields: [
        {
          label: 'What time did your child  go to bed?',
          type: 'time'
        },
        {
          label: 'What time did your child  fall asleep?',
          type: 'time'
        },
        {
          label: 'Did your child wake up during the night?',
          type: 'radio',
          options: ['Yes', 'No']
        },
        {
          label: 'How many times did your child wake up?',
          type: 'number',
          condition: {
            field: 'Did your child wake up during the night?',
            value: 'Yes'
          }
        },
        {
          label: 'What were the times of these wakings?',
          type: 'text',
          append: '',
          condition: {
            field: 'Did your child wake up during the night?',
            value: 'Yes'
          }
        },
        {
          label: 'How long did it take for your child to fall to sleep again?',
          placeholder: 'Felt like 4 hours, was actually 10 minutes',
          type: 'text',
          condition: {
            field: 'Did your child wake up during the night?',
            value: 'Yes'
          }
        },
        {
          label: 'What time did your child finally wake up (and did not go back to sleep)?',
          type: 'time'
        },
        {
          label: 'What time did your child get out of bed?',
          type: 'time'
        },
        {
          label: 'Did your child nap during the day?',
          type: 'radio',
          options: ['Yes', 'No']
        },
        {
          label: 'Did your child sleep in their own bed?',
          type: 'radio',
          options: ['Yes', 'No']
        },
        {
          label: 'Did your child sleep alone?',
          type: 'radio',
          options: ['Yes', 'No']
        },
        {
          label: 'Did you notice anything during the night?',
          type: 'select',
          multiple: true,
          options: [
            'snoring/heavy breathing',
            'restless legs',
            'pain',
            'crying',
            'sleep walking',
            'sleep talking',
            'reporting dreams/nightmares',
            'anxiety'
          ]
        }
      ] as any,
      formValues:
        entryId.value && child.value.calendarEntries && child.value.calendarEntries[entryId.value]
          ? { ...child.value.calendarEntries[entryId.value] }
          : {}
    });

    const canSubmit = computed(
      () => Object.keys(form.formValues).length === form.formFields.length
    );

    const forceChange = function forceChange(label) {
      return function(newVal) {
        form.formValues[label] = newVal;
        form.hasChanges = true;
      };
    };

    const showCondition = field => {
      if (!field.condition) {
        return true;
      }

      if (
        form.formValues[field.condition.field] &&
        form.formValues[field.condition.field] === field.condition?.value
      ) {
        return true;
      }

      return false;
    };

    const saveChanges = async function saveFormChanges(e) {
      if (form.hasBeenSubmitted) {
        return;
      }

      form.hasBeenSubmitted = true;

      // const formValues = Object.fromEntries(new FormData(e.target));
      const data = new FormData(e.target);
      // ionic sucks with checkboxes and radios....
      const radioData = [...form.formFields].reduce((all = {}, field) => {
        if (field.type === 'radio' && form.formValues[field.label]) {
          all[field.label] = form.formValues[field.label];
        }

        return all;
      }, {});

      const selectData = [...form.formFields].reduce((all = {}, field) => {
        if (field.type === 'select' && field.multiple && form.formValues[field.label]) {
          if (typeof form.formValues[field.label] === 'string') {
            all[field.label] = form.formValues[field.label].split(',');
          } else {
            all[field.label] = [...form.formValues[field.label]];
          }
        }

        return all;
      }, {});

      const formData = Object.assign(Object.fromEntries(data.entries()), radioData, selectData);
      const url = `wp-json/user/v1/child-entry/${childId.value}/${entryId.value}?empty-to-null=true`;

      await axios
        .post(url, formData)
        .then(submitSuccessful)
        .catch(submitFailed);
    };

    async function submitSuccessful() {
      await store.dispatch('user/getDataAPI');

      const toast = await toastController.create({
        color: 'success',
        message: 'Your changes have been saved.',
        duration: 5000,
        buttons: [
          {
            text: 'x',
            role: 'cancel'
          }
        ]
      });
      toast.present();
      resetForm();

      router.push(`/profile/child/${childId.value}/calendar`);
    }

    const resetForm = () => {
      form.hasChanges = false;
      form.hasBeenSubmitted = false;
    };

    async function submitFailed(err) {
      const message = err?.response?.data?.message || 'Error saving your changes.';

      const toast = await toastController.create({
        color: 'danger',
        message: message,
        duration: 5000
      });

      form.hasBeenSubmitted = false;

      return toast.present();
    }

    onMounted(() => {
      setTimeout(() => (form.hasLoaded = true), 0);
    });

    return {
      chevronBackOutline,
      canSubmit,
      forceChange,
      entryId,
      childId,
      child,
      saveChanges,
      showCondition,
      ...toRefs(form)
    };
  }
};
</script>
