
import axios from '@/axios';
import { computed, reactive, toRefs, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import {
  IonText,
  IonTextarea,
  IonDatetime,
  IonRadioGroup,
  IonListHeader,
  IonRadio,
  IonLabel,
  IonInput,
  IonCol,
  IonRow,
  IonPage,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  toastController
} from '@ionic/vue';

import { chevronBackOutline } from 'ionicons/icons';

export default {
  name: 'ChildCalendarEdit',
  components: {
    IonText,
    IonTextarea,
    IonDatetime,
    IonRadioGroup,
    IonListHeader,
    IonRadio,
    IonLabel,
    IonInput,
    IonCol,
    IonRow,
    IonPage,
    IonButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent
  },
  setup(): any {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const childId = computed(() => Number(route.params.childId) || null);
    const entryId = computed(() => Number(route.params.entryId) || null);
    const child = computed(() => store.getters['user/childById'](childId.value));

    const form = reactive({
      hasLoaded: false,
      hasBeenSubmitted: false,
      hasChanges: false,
      formFields: [
        {
          label: 'What time did your child  go to bed?',
          type: 'time'
        },
        {
          label: 'What time did your child  fall asleep?',
          type: 'time'
        },
        {
          label: 'Did your child wake up during the night?',
          type: 'radio',
          options: ['Yes', 'No']
        },
        {
          label: 'How many times did your child wake up?',
          type: 'number',
          condition: {
            field: 'Did your child wake up during the night?',
            value: 'Yes'
          }
        },
        {
          label: 'What were the times of these wakings?',
          type: 'text',
          append: '',
          condition: {
            field: 'Did your child wake up during the night?',
            value: 'Yes'
          }
        },
        {
          label: 'How long did it take for your child to fall to sleep again?',
          placeholder: 'Felt like 4 hours, was actually 10 minutes',
          type: 'text',
          condition: {
            field: 'Did your child wake up during the night?',
            value: 'Yes'
          }
        },
        {
          label: 'What time did your child finally wake up (and did not go back to sleep)?',
          type: 'time'
        },
        {
          label: 'What time did your child get out of bed?',
          type: 'time'
        },
        {
          label: 'Did your child nap during the day?',
          type: 'radio',
          options: ['Yes', 'No']
        },
        {
          label: 'Did your child sleep in their own bed?',
          type: 'radio',
          options: ['Yes', 'No']
        },
        {
          label: 'Did your child sleep alone?',
          type: 'radio',
          options: ['Yes', 'No']
        },
        {
          label: 'Did you notice anything during the night?',
          type: 'select',
          multiple: true,
          options: [
            'snoring/heavy breathing',
            'restless legs',
            'pain',
            'crying',
            'sleep walking',
            'sleep talking',
            'reporting dreams/nightmares',
            'anxiety'
          ]
        }
      ] as any,
      formValues:
        entryId.value && child.value.calendarEntries && child.value.calendarEntries[entryId.value]
          ? { ...child.value.calendarEntries[entryId.value] }
          : {}
    });

    const canSubmit = computed(
      () => Object.keys(form.formValues).length === form.formFields.length
    );

    const forceChange = function forceChange(label) {
      return function(newVal) {
        form.formValues[label] = newVal;
        form.hasChanges = true;
      };
    };

    const showCondition = field => {
      if (!field.condition) {
        return true;
      }

      if (
        form.formValues[field.condition.field] &&
        form.formValues[field.condition.field] === field.condition?.value
      ) {
        return true;
      }

      return false;
    };

    const saveChanges = async function saveFormChanges(e) {
      if (form.hasBeenSubmitted) {
        return;
      }

      form.hasBeenSubmitted = true;

      // const formValues = Object.fromEntries(new FormData(e.target));
      const data = new FormData(e.target);
      // ionic sucks with checkboxes and radios....
      const radioData = [...form.formFields].reduce((all = {}, field) => {
        if (field.type === 'radio' && form.formValues[field.label]) {
          all[field.label] = form.formValues[field.label];
        }

        return all;
      }, {});

      const selectData = [...form.formFields].reduce((all = {}, field) => {
        if (field.type === 'select' && field.multiple && form.formValues[field.label]) {
          if (typeof form.formValues[field.label] === 'string') {
            all[field.label] = form.formValues[field.label].split(',');
          } else {
            all[field.label] = [...form.formValues[field.label]];
          }
        }

        return all;
      }, {});

      const formData = Object.assign(Object.fromEntries(data.entries()), radioData, selectData);
      const url = `wp-json/user/v1/child-entry/${childId.value}/${entryId.value}?empty-to-null=true`;

      await axios
        .post(url, formData)
        .then(submitSuccessful)
        .catch(submitFailed);
    };

    async function submitSuccessful() {
      await store.dispatch('user/getDataAPI');

      const toast = await toastController.create({
        color: 'success',
        message: 'Your changes have been saved.',
        duration: 5000,
        buttons: [
          {
            text: 'x',
            role: 'cancel'
          }
        ]
      });
      toast.present();
      resetForm();

      router.push(`/profile/child/${childId.value}/calendar`);
    }

    const resetForm = () => {
      form.hasChanges = false;
      form.hasBeenSubmitted = false;
    };

    async function submitFailed(err) {
      const message = err?.response?.data?.message || 'Error saving your changes.';

      const toast = await toastController.create({
        color: 'danger',
        message: message,
        duration: 5000
      });

      form.hasBeenSubmitted = false;

      return toast.present();
    }

    onMounted(() => {
      setTimeout(() => (form.hasLoaded = true), 0);
    });

    return {
      chevronBackOutline,
      canSubmit,
      forceChange,
      entryId,
      childId,
      child,
      saveChanges,
      showCondition,
      ...toRefs(form)
    };
  }
};
